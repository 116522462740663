import './App.css';

import Routes from '@/routes/index';

function App() {

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    };

    return (
        <div className="App">
            <Routes />
        </div>
    );
}

export default App;
